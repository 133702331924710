@media (max-width: 576px) {
  .container {
    font-size: 12px;
    padding: 0;
    .wrapper {
      width: 100%;
    }
  }
  .hidden_path {
    width: 10%;
    max-width: 15px;
    height: 15px;
  }
  .intro_wrapper {
    width: 100vw;
    height: 100vh;
    background-size: cover;

    .picking_eating {
      min-width: 300px;
      max-height: 50px;
      margin-bottom: 50px;
      > .picking,
      .eating {
        font-size: 13px;
        max-height: inherit;
      }
    }
  }
  .sensor_wrap {
    .sensor {
      border-width: 2px;
      svg {
        font-size: 25px;
      }
    }
  }
  .popup_card {
    width: 80%;
    max-width: 400px;
    min-width: 350px;
  }
  .chat {
    padding: 8px;
  }
  .popup {
    .touch_btn {
      font-size: 12px;
    }
  }
  .stt {
    font-size: 12px;
  }
  .stt.user,
  .stt.ai {
    max-height: 30px;
  }

  .wrapper {
    .reset {
      padding: 5px 0 0 5px;
    }
    .menu_group_listbox {
      height: 8%;
      .left_btn,
      .right_btn {
        width: 25px;
        height: 25px;
        svg {
          font-size: 15px;
        }
      }
      .menu_group_itembox {
        .menu_group_item {
          padding: 5px;
          min-height: 30px;
          max-height: 50px;
          font-size: 12px;
        }
      }
    }

    .menu_listbox {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
      padding: 2px 5px;
      font-size: 12px;
      .menu_img {
        font-size: 12px;
      }
      .menu_item {
        max-height: 100px;
        min-height: 80px;
        .menu_content {
          .menu_name {
            font-size: 12px;
          }
        }
      }
    }
    .order_listbox {
      padding: 0 5px;
      .order_list {
        .order_item {
          .order_item_img {
            font-size: 12px;
          }
          .order_item_content {
            font-size: 12px;
            height: 50%;
          }
        }
      }
      .order_status_box {
        .count {
          font-size: 12px;
        }
        .btn_group {
          min-width: 200px;
          .cancel_btn,
          .cash_btn,
          .card_btn {
            font-size: 10px;
            padding: 0;
            svg {
              font-size: 10px;
            }
          }
        }
      }
    }
    .audio_switch_box {
      .audio_text {
        padding: 0;
        font-size: 12px;
        > div {
          width: 80%;
          min-width: 120px;
          max-width: 300px;
          min-height: 35px;
        }
        .guide_text_wrap {
          .guide_text {
            padding-left: 1rem;
          }
          svg {
            font-size: 2rem;
          }
        }
        .results {
          > span {
            max-width: 5px;
            max-height: 5px;
          }
        }
      }
    }
  }
  .no_voice {
    > div {
      min-width: 130px;
      font-size: 10px;
    }
  }

  .flags {
    width: 40%;
    column-gap: 10px;
    > div {
      max-height: 30px;
    }
  }
}

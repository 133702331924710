.hidden_path {
  position: fixed;
  width: 20px;
  height: 20px;
  background-color: #fae6c8;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.container {
  max-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #fae6c8;
  // background-color: #000000;
}

.intro_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-width: 1256px;

  background-image: url('/assets/img/jaws.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &.togoOrDinein {
    justify-content: space-between;
  }
}

.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 55%;
  max-width: 690px;
  height: 20vh;
  background-color: rgba(165, 176, 196, 0.9);
  max-width: 1256px;
  // border: 1px solid rgba(240, 240, 240, 0.6);
  box-shadow: 0 0 5px #bbbbbb;
  min-width: 300px;
}

.chat {
  z-index: 10;
  font-size: 1.2rem;
  list-style: none;
  padding: 1rem 1.5rem;
  width: 80%;
  height: 80%;
  display: flex;
  min-width: 250px;
  flex-direction: column;
  > div {
    margin-bottom: 10px;
  }
}

.stt {
  font-size: 1rem;
  width: auto;
  height: 50px;
  min-width: 120px;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  position: relative;
}

.stt.ai {
  background-color: #ececec;
  color: #0e0e0e;
  border-radius: 15px 15px 15px 0;
  max-width: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stt.ai::before {
  border-top: 0 solid transparent;
  border-left: 15px solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 15px solid #ececec;
  content: '';
  position: absolute;
  bottom: 0;
  left: -15px;
}

.stt.user {
  background-color: #fa4759;
  color: #ececec;
  border-radius: 15px 15px 0 15px;
  align-self: flex-end;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  > div {
    position: absolute;
    top: 5px;
    right: 12px;
    border-radius: 6px;
    width: 6px;
    height: 6px;
  }
}
.stt.user::after {
  border-top: 0 solid transparent;
  border-left: 0 solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fa4759;
  content: '';
  position: absolute;
  bottom: 0;
  right: -15px;
}

.mic_off {
  background-color: #ad0000;
}
.mic_on {
  background-color: #16a803;
}
.touch_btn {
  background-color: #334a79;
  width: 100%;
  height: 20%;
  border: none;
  color: #ececec;
  font-size: 1rem;
}

.sensor_wrap {
  position: absolute;
  top: 90%;
  height: auto;
  z-index: 1;
  display: flex;
  min-width: 200px;
  justify-content: space-around;
  width: 40%;
  max-width: 300px;
  cursor: pointer;

  .sensor {
    background-color: #f2f2f2;
    text-align: center;
    font-weight: 600;
    padding: 5px 0;
    width: 70%;
    font-size: 1.1rem;
    min-width: 80px;
    display: flex;
    justify-content: center;
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    // background-image: url('/assets/img/sensor.jpg');
  }
}

.popup_card {
  background-image: url('../assets/img/info.png');
  background-repeat: no-repeat;
  width: 50%;
  min-width: 40px;
  max-width: 480px;
  margin: 0 auto;
  height: 50vh;
  background-size: contain;
  background-position: center;
}

.picking_eating {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-width: thick;
  height: 10vh;
  max-width: 690px;
  box-sizing: border-box;
  width: 55%;
  background-color: #16100d;
  z-index: 2;
  margin-bottom: 30px;

  .picking,
  .eating {
    border: 1px #2e2926 dashed;
    height: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-width: 1256px;
  background-color: rgb(27, 27, 27);

  .reset {
    width: 100%;
    background-color: #f9f5e7;
    padding-left: 1rem;
    padding-top: 1rem;
    > span {
      width: 20%;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .menu_group_listbox {
    height: 10%;
    width: 100%;
    background-color: #f9f5e7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .left_btn,
    .right_btn {
      width: 50px;
      height: 50px;
      border: none;
      border-radius: 100%;
      background-color: #f8ead8;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        color: rgb(255, 255, 255);
        background-color: #9e5a44;
        transition: 0.7s;
      }
    }
    .menu_group_itembox {
      width: 85%;
      height: 90%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .menu_group_item {
        width: 100%;
        height: auto;
        background-color: #a77260;
        color: #fff;
        border: none;
        border-radius: 10px;
        padding: 15px;
        min-height: 30px;
        font-size: 1rem;
        font-weight: bold;
        margin: 0 3px;
        max-width: 7rem;
        word-break: keep-all;
        z-index: 1;
        cursor: pointer;
        &:hover,
        &.acitve {
          color: rgb(255, 255, 255);
          background-color: #63392d;
          transition: 0.7s;
        }
      }
    }
  }
  .menu_listbox {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 55%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 10px;
    overflow: auto;
    padding: 15px 4rem;
    .menu_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      min-height: 180px;
      background-color: #f9f5e7;
      border-radius: 10px;
      border: none;
      cursor: pointer;
      &:hover {
        color: rgb(255, 255, 255);
        background-color: #9e5a44;
        transition: 0.7s;
        .menu_img {
          width: 100%;
          height: 65%;
          border-radius: 10px 10px 0 0;
          background-color: #9e5a44;
        }
      }
      .menu_img {
        width: 100%;
        height: 65%;
        border-radius: 10px 10px 0 0;
        // background-color: #f8ead8;
        border-radius: 10px 10px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > img {
          width: 100%;
          height: 90%;
        }
      }
      .menu_content {
        width: 100%;
        height: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .menu_name {
          font-size: 1rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .order_listbox {
    width: 100%;
    height: 20%;
    background-color: #f9f5e7;
    display: flex;
    flex-direction: column;
    padding: 0 5rem;
    .order_list {
      padding: 1rem 0;
      width: 100%;
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .order_item {
        width: 23%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border: none;
        background: none;
        align-items: center;
        margin: 0 5px;
        .order_item_img {
          font-size: 1rem;
          font-weight: bold;
          height: 65%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #f8ead8;
          width: 100%;
          > img {
            width: 90%;
            height: 90%;
          }
        }
        .order_item_content {
          font-size: 1rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
          height: 30%;
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
        }
      }
    }
    .order_status_box {
      width: 100%;
      height: 25%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      .count {
        font-size: 1.5rem;
        font-weight: bold;
        height: 100%;
        display: flex;
        width: 15%;
        flex-direction: column;
        justify-content: center;
      }
      .btn_group {
        width: 46%;
        height: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: bold;
        > button {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }
        .cancel_btn {
          width: 33%;
          height: 100%;
          background-color: #e64b40;
          border: none;
          color: white;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            color: rgb(255, 255, 255);
            background-color: #df2518;
            transition: 0.7s;
          }
        }
        .cash_btn {
          width: 33%;
          height: 100%;
          background-color: #408de6;
          border: none;
          color: white;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            color: rgb(255, 255, 255);
            background-color: #579ff1;
            transition: 0.7s;
          }
        }
        .card_btn {
          width: 33%;
          height: 100%;
          background-color: #291ba7;
          border: none;
          color: white;
          border-radius: 5px;
          font-weight: bold;
          cursor: pointer;
          &:hover {
            color: rgb(255, 255, 255);
            background-color: #1a07be;
            transition: 0.7s;
          }
        }
      }
    }
  }

  .audio_switch_box {
    height: 20%;
    background-color: #eddbc7;
    // background-color: #f7f7f7;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .audio_icon {
      width: 20%;
      height: 80%;
      border: none;
      border-radius: 13rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #3d1b06;
      font-size: 7rem;
      font-weight: bold;
      margin-right: 2rem;
      cursor: pointer;
      &:hover {
        color: rgb(241, 236, 236);
        background-color: #3d1b06;
        transition: 0.7s;
      }
      .audio_icon_img {
        width: 100%;
        height: 100%;
        background-color: #f8ead8;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }

    .audio_text {
      width: 90%;
      font-size: 1.5rem;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      padding: 0 2rem;
      > div {
        padding: 10px;
        width: 65%;
        min-height: 50px;
        overflow-y: auto;
      }
      .guide_text_wrap {
        align-self: flex-start;
        display: flex;
        flex-direction: row;
        padding: 0;
        align-items: flex-end;

        .guide_text {
          width: 100%;
          margin-left: 10px;
          background-color: #f7f7f7;
          position: relative;
          display: flex;
          padding: 10px 0;
          padding-left: 20px;
          border-radius: 20px 20px 20px 0;
        }
      }
      .results {
        background-color: #a77260;
        color: #e2e2e2;
        margin-top: 20px;
        align-self: flex-end;
        text-align: end;
        position: relative;
        border-radius: 20px 20px 0 20px;
        padding-left: 25px;

        > span {
          display: inline-block;
          position: absolute;
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 100%;
        }
      }
    }
  }
}
.order_end {
  width: 100vw;
  height: 100vh;
  position: absolute;
  max-width: 1256px;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: #fae6c8;
  // border: 1px solid red;
  // background-image: url('../assets/img/finish.png');
  // background-repeat: no-repeat;
  // background-size: contain;
}

.no_voice {
  > div {
    width: 40%;
    border: 5px solid #f9f5e7;
    border-radius: 30px;
    text-align: center;
    color: rgb(231, 231, 231);
    background-color: #a77260;
    padding: 1.1rem 0;
    font-size: 1.5rem;
    font-weight: 700;

    &:hover {
      background-color: #63392d;
    }
  }
}

.modal {
  font-size: 12px;
  display: block;
  max-width: 50vw;
  border: 1px solid;
  border-radius: 1rem;
  background-color: #ffffff;
  > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  > .content {
    width: 100%;
    padding: 10px 5px;
  }
  > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
}

.flags {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
  width: 50%;
  max-width: 300px;
  min-width: 90px;
  position: absolute;
  top: 12px;

  height: 4vh;
  max-height: 60px;
  min-height: 30px;

  > div {
    min-width: 30px;
    height: 100%;
    max-height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .ko {
    background-image: url('../assets/img/flags/ko.jpg');
  }
  .en {
    background-image: url('../assets/img/flags/en.jpg');
    background-position: left;
  }
  .ja {
    background-image: url('../assets/img/flags/ja.jpg');
  }
  .zh {
    background-image: url('../assets/img/flags/zh.jpg');
    background-position: left;
  }
}
